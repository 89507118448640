// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Import Rails base
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "jquery";
import "jquery.easing";
import videojs from "video.js";
import flatpickr from "flatpickr";

window.videojs = videojs;

// Require images
const images = require.context("../images", true);

// Require fonts
require.context("../fonts/", true);

// Theme
require.context("../themes", true);

// Import Bootstrap
import "../stylesheets/application";
import "../stylesheets/flatpickr";

// Require toastr
global.toastr = require("toastr");

// Require video.js
import AppointmentPlayer from "../js/appointment_player.js";
import KeyIncidentPlayer from "../js/key_incident_player.js";
window.AppointmentPlayer = AppointmentPlayer;
window.KeyIncidentPlayer = KeyIncidentPlayer;

import Appointment from "../js/appointment.js";

// Start Rails base
Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Import app javascripts
import "../js/analytics.js";
import "../js/direct_uploads.js";
import "../js/users.js";
import "../js/fixtures.js";
import "../js/user_fixture.js";
import "../js/common.js";
import "../js/horizontal-nav.js";
import "../themes/main/admin/vendors/stacktable/stacktable.js";
import "../themes/main/admin/vendors/fabric/fabric.min.js";
import "../js/scripts.js";
import "../js/sb-admin-charts.min.js";
import "../js/reports.js";
import "../js/question_reports.js";
import "../js/appointment_report.js";
import "../js/key_incidents.js";
import "../js/video_centre.js";
import "../js/flatpickr_initializer";
import "../js/fullcalendar";
import "../js/calendar";


import FilterForm from "../js/filter_form.js";
import UserFixture from "../js/user_fixture.js";
import MarkupPlayer from "../js/markup_player.js";
window.MarkupPlayer = MarkupPlayer;

import Observation from "../js/observation.js";
import EditObservationCompetency from "../js/edit_observation_competency.js";
window.Observation = Observation;
window.EditObservationCompetency = EditObservationCompetency;

// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  window.appointment_player = new AppointmentPlayer();
  window.key_incident_player = new KeyIncidentPlayer();
  window.appointment = new Appointment();
  window.user_fixture = new UserFixture();
  window.observation = new Observation();
  window.edit_observation_competency = new EditObservationCompetency();
  window.filter_form = new FilterForm();
});

document.addEventListener("turbolinks:before-visit", () => {
  window.appointment_player.dispose();
  window.key_incident_player.dispose();
});
